import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { initialize, LDClient, LDContext, LDFlagSet } from "launchdarkly-js-client-sdk";
import { environment } from '../../environments/environment';
import { LogService } from './log-service';
import { LocalStorageService } from '@hawaiianair/core';
import { CorrelationContainerService } from '@hawaiianair/common';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyClientService {
  public ldClient: LDClient;
  private flags: LDFlagSet;
  // Subject to publish new flags coming along with SSE 'change'
  // using Subject for multicase purpose as multiple components will be subscribing
  newFlags: Subject<Object> = new Subject<Object>();

  constructor(
    private logService: LogService,
    private localStorageSvc: LocalStorageService,
    private correlationContainer: CorrelationContainerService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.flags = {};
    this.initLdClient();
  }

  setFlags() {
    this.flags = this.ldClient.allFlags();
    // this.logService.log("LD Flags all set", JSON.stringify(this.flags));
  }

  getFlag(key: string) {
    return this.flags[key];
  }

  changeUser(origin: string, destination: string, isNRSA: boolean) {
    const newLdUser: LDContext = {
      kind: 'user',
      key: this.correlationContainer.generateNewId(),
      origin,
      destination,
      isNRSA
    };
    this.ldClient.identify(newLdUser);
    this.localStorageSvc.set('LAUNCHDARKLY_USER', newLdUser);
  }

  initLdClient() {
    // If the app is not running on browser,
    // i.e, window is undefined, just return
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // If client already init, return
    if (!!this.ldClient) {
      return;
    }

    // this.logService.log('LD service is about to init');

    // check if local storage has a LD key
    const curLdUser = this.localStorageSvc.get('LAUNCHDARKLY_USER');
    if (!curLdUser) { // curLdUser === null
      // create new user
      const context: LDContext = {
        kind: 'user',
        key: this.correlationContainer.generateNewId(),
      };
      // save it in LocalStorage
      this.localStorageSvc.set('LAUNCHDARKLY_USER', context);
      this.ldClient = initialize(environment['ldClientSideId'], context);
      // this.logService.log('LaunchDarklyClientService: initialized with new user: ' + JSON.stringify(newLdUser));
    } else {
      this.ldClient = initialize(environment['ldClientSideId'], curLdUser);
      // this.logService.log('LaunchDarklyClientService: initialized with existing user: ' + JSON.stringify(curLdUser));
    }

    this.ldClient.on('ready', () => {
      this.setFlags();
    });

    this.ldClient.on('change', (newFlags) => {
      // this.logService.log('LaunchDarklyClientService: SSE change received : ' + JSON.stringify(newFlags));

      Object.keys(this.flags).forEach(
        key => {
          if (newFlags[key] !== undefined) {
            this.flags[key] = newFlags[key].current;
          }
        }
      );

      this.newFlags.next(this.flags);
      // this.logService.log("Flags updated", JSON.stringify(this.flags));
    });

    this.ldClient.on('error', e => {
      this.logService.log('LaunchDarklyClientService: error occurred ==>', JSON.stringify(e));
    });
  }

  waitUntilReady(): Promise<void> {
    return this.ldClient?.waitUntilReady();
  }
}

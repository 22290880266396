import { AmadeusPaymentEnvironment } from '@hawaiianair/payment';
import { HeaderKeys } from '~app/models/payment-headers.model';
import { Environment } from './checkin-environment-interface';

export const environment: Environment = {
  production: false,
  contentApiHost: "/",
  appName: "checkin-angular-web",
  appId: "298dab6a-1f5a-4c07-b792-2212e5e93408",
  appPath: "checkin",
  allowedLocales: "en,ja,ko,zh,zh-Hant,en-NZ,en-AU",
  defaultLanguage: "en",
  defaultCountry: "us",
  defaultCurrency: "USD",
  externalDomain: "flightcheckin.hawaiianairlines.com",
  defaultServerRoute: "/",
  updateChromeOnLanguageChange: true,
  pageChromeRoute: "/page-chrome",
  buildInfoCheckMinutes: 5,
  buildArtifactsPath: "./dist",
  ldClientSideId: "5c9ad6eb4de8100813855a4b",
  deployRootPath: "/",
  logging: {
    logToConsole: true,
    instrumentationKey: 'a58b8120-4778-4670-8d90-dc16d066d657', // insert test ApplicationInsights key here
  },
  api: {
    baseUrl: 'https://public-test.nonprod.itservices.hawaiianairlines.com',
    basePath: {
      default: 'exp-web-day-of-travel/v2/api',
    },
    tokenEnv: 'test',
    headers: {
      xHaChannel: {
        // @ts-ignore
        key: HeaderKeys.XHaChannel,
        value: 'WEB'
      },
      xHaBusinessDomain: {
        // @ts-ignore
        key: HeaderKeys.XHaBusinessDomain,
        value: 'CHECKIN'
      },
      contentType: {
        // @ts-ignore
        key: HeaderKeys.ContentType,
        value: 'application/json'
      },
      xRootCorrelationId: {
        // @ts-ignore
        key: HeaderKeys.XRootCorrelationId,
        value: ''
      },
      Authorization: {
        // @ts-ignore
        key: HeaderKeys.Authorization,
        value: ''
      },
      xRootSessionId: {
        // @ts-ignore
        key: HeaderKeys.XRootSessionId,
        value: ''
      }
    },
  },
  paymentProfileId: 'qfNwRH',
  expWebCheckinApiAuth: "65ecc696552c4e878b9b427000318d48:66F65fb64e684827958e9cafA820BbCC",
  "exp-web-checkin-v2-api": "https://public-test.nonprod.itservices.hawaiianairlines.com/exp-web-day-of-travel/v2/api/",
  customerInternalReference: "WEB_CHECKIN_SPA",
  amadeusPaymentApi: {
    url: 'https://test.hawaiian.airlines.api.amadeus.com/v1',
    primaryAccount: {
      clientId: 'YWmgtm3H2AhRefAWvuxkjO6Azf8hh2zT',
      clientSecret: 'AXXAhKYePFP95xaL',
    },
    secondaryAccount: {
      clientId: 'YWmgtm3H2AhRefAWvuxkjO6Azf8hh2zT',
      clientSecret: 'AXXAhKYePFP95xaL',
    },
    officeId: 'HNLHA08CI',
    sdkUrl: 'https://paypages.payment.amadeus.com/1ASIATP/ARIAPP/static/checkout/2.1.1/sdk-es2015.js',
    sdkId: 'SDKWEB-TOKENIZATION-CHECKIN',
    environment: AmadeusPaymentEnvironment.uat
  },
  taggingScript: 'https://assets.adobedtm.com/e272d7f0f44e/04b2c583e809/launch-9e0c1ece5155-staging.min.js',
  spaDisabledUrls: {
    'ja-jp': 'http://test.hawaiianairlines.co.jp/system-maintenance',
    'ko-kr': 'http://test.hawaiianairlines.co.kr/system-maintenance',
    'en-au': 'http://test.hawaiianairlines.com.au/system-maintenance',
    'en-nz': 'http://test.hawaiianairlines.co.nz/system-maintenance',
    'en-us': 'http://test.hawaiianairlines.com/system-maintenance',
  }
};
